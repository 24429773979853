import { createSelector } from "@reduxjs/toolkit";

import { getDeploymentSettingsRequest } from "Api/organizations/projects/getDeploymentSetting.request";
import { getServiceIcon } from "Components/ServiceIcon/helper";
import { DIALOG_RESOURCE_PROFILE } from "Constants/constants";
import { isDifferent } from "Libs/difference";
import logger from "Libs/logger";
import { setDeep } from "Libs/objectAccess";
import { objectEntries, getProjectId, isJson, roundedNumber } from "Libs/utils";
import { updateServices } from "Reducers/environment/service";
import {
  projectStateSelector,
  gitProjectSelector
} from "Reducers/project/project";
import { projectSettingsSizingApiSelector } from "Reducers/projectSettings";
import {
  getDeploymentWebApps,
  getDeploymentServices
} from "src/organization/common/containers/ServicesDisplay/util/serviceGraph";
import { AppDispatch, GetState, RootState } from "Store/configureStore";

import type {
  Deployment,
  DeploymentServices,
  DeploymentService,
  DeploymentUpdateParams
} from "@packages/client";

const NEXT_DEPLOYMENT_ID = "next";
const CURRENT_DEPLOYMENT_ID = "current";

const LOAD_DEPLOYMENT_START = "app/environmentDeployment/load_start";
const LOAD_DEPLOYMENT_SKIP = "app/environmentDeployment/load_skip";
const LOAD_DEPLOYMENT_SUCCESS = "app/environmentDeployment/load_success";
const LOAD_DEPLOYMENT_FAILURE = "app/environmentDeployment/load_failure";

const UPDATE_DEPLOYMENT_START = "app/environmentDeployment/update_start";
const UPDATE_DEPLOYMENT_SUCCESS = "app/environmentDeployment/update_success";
const UPDATE_DEPLOYMENT_FAILURE = "app/environmentDeployment/update_failure";

const LOAD_DEPLOYMENT_SETTINGS_START =
  "app/environmentDeploymentSettings/load_start";
const LOAD_DEPLOYMENT_SETTINGS_SUCCESS =
  "app/environmentDeploymentSettings/load_success";
const LOAD_DEPLOYMENT_SETTINGS_FAILURE =
  "app/environmentDeploymentSettings/load_failure";

export type ModifiedDeploymentService = Omit<DeploymentService, "disk"> & {
  disk?: string;
};
export type ModifiedDeploymentServices = {
  [x: string]: ModifiedDeploymentService;
};
export type ModifiedFlexibleResources = {
  webapps: ModifiedDeploymentServices;
  services: ModifiedDeploymentServices;
  workers: DeploymentServices;
};
export type FlexibleResources = {
  webapps: DeploymentServices;
  services: DeploymentServices;
  workers: DeploymentServices;
};

export type ResourceCollectionType = keyof FlexibleResources;

export const deploymentSelector = (state: RootState) => state.deployment;

export const deploymentVariablesSelector = createSelector(
  deploymentSelector,
  projectStateSelector,
  (
    _: RootState,
    organizationId: string,
    projectId: string,
    environmentId?: string
  ) => ({
    organizationId,
    projectId,
    environmentId
  }),
  (
    deploymentState,
    projectState,
    { organizationId, projectId, environmentId }
  ) => {
    if (!environmentId) {
      environmentId =
        projectState?.data?.[organizationId]?.[projectId]?.default_branch;
    }

    if (!environmentId) {
      return;
    }

    return deploymentState.data?.[organizationId]?.[projectId]?.[environmentId]
      ?.current?.variables;
  }
);

export const generalDeploymentErrorSelector = createSelector(
  deploymentSelector,
  (
    _: RootState,
    params: { organizationId: string; projectId: string; environmentId: string }
  ) => params,
  (deployment, { organizationId, projectId, environmentId }) =>
    deployment?.errors?.[organizationId]?.[projectId]?.[environmentId]
);

export const currentDeploymentErrorSelector = createSelector(
  generalDeploymentErrorSelector,
  environmentObject => environmentObject?.current
);

export const nextDeploymentErrorSelector = createSelector(
  generalDeploymentErrorSelector,
  environmentObject => environmentObject?.next
);
export const deploymentErrorSelector = createSelector(
  currentDeploymentErrorSelector,
  nextDeploymentErrorSelector,
  (currentError, nextError) =>
    (currentError?.message
      ? currentError
      : nextError?.message
        ? nextError
        : undefined) as { message: string; code?: number } | undefined
);

const BLACKFIRE_ID_VARIABLE = "env:BLACKFIRE_SERVER_ID";

export const blackfireEnabledInEnvironmentSelector = createSelector(
  deploymentVariablesSelector,
  variables =>
    variables?.some(variable => variable.name === BLACKFIRE_ID_VARIABLE)
);

export const blackfireUUIDSelector = createSelector(
  deploymentVariablesSelector,
  variables =>
    variables?.find(variable => variable.name === BLACKFIRE_ID_VARIABLE)?.value
);

export const isLoadingSelector = createSelector(
  deploymentSelector,
  deployment => deployment.loading
);

export const isUpdateLoadingSelector = createSelector(
  deploymentSelector,
  deployment => deployment.updateLoading
);

export const allEnvironmentsResource = createSelector(
  deploymentSelector,
  (_: RootState, params: { organizationId: string; projectId: string }) =>
    params,
  (deployment, { organizationId, projectId }) => {
    const environmentLevel = deployment.data?.[organizationId]?.[projectId];
    const resources = Object.values(environmentLevel ?? {}).map(
      deploymentObj => deploymentObj?.[CURRENT_DEPLOYMENT_ID]
    );

    return resources?.filter(resource => typeof resource !== "undefined");
  }
);
export const loadDeploymentSettings = ({
  projectId,
  environmentId,
  organizationId
}) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: LOAD_DEPLOYMENT_SETTINGS_START,
      payload: {
        projectId,
        environmentId,
        organizationId
      }
    });
    try {
      const deploymentSettings = await getDeploymentSettingsRequest({
        projectId,
        environmentId
      });

      dispatch({
        type: LOAD_DEPLOYMENT_SETTINGS_SUCCESS,
        payload: deploymentSettings,
        meta: { environmentId, projectId, organizationId }
      });
      return LOAD_DEPLOYMENT_SETTINGS_SUCCESS;
    } catch (error) {
      const errorMessage = isJson(error)
        ? error
        : "An error occurred while attempting to load deployment settings.";
      logger(errorMessage, {
        action: "load_deployment_settings"
      });

      dispatch({
        type: LOAD_DEPLOYMENT_SETTINGS_FAILURE,
        error: true,
        payload: {
          error,
          projectId,
          environmentId,
          organizationId
        }
      });
    }
  };
};

export const loadDeployment = (
  organizationDescriptionId: string | undefined,
  projectDescriptionId: string | undefined,
  environmentDescriptionId: string,
  settings: {
    hasRedeployed?: boolean;
    next?: boolean;
    reload?: boolean;
    escapeLoading?: boolean;
  } = {}
) => {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const { hasRedeployed, next, reload, escapeLoading } = settings;

    const deploymentIsLoading = isLoadingSelector(getState());

    if (!escapeLoading) {
      if (!reload && !hasRedeployed && deploymentIsLoading) {
        dispatch({ type: LOAD_DEPLOYMENT_SKIP });
        return;
      }
    }

    dispatch({ type: LOAD_DEPLOYMENT_START, next });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const encodedEnvId = encodeURIComponent(environmentDescriptionId);

      const projectId = getProjectId(getState, projectDescriptionId)!;
      const deployment = next
        ? await client.getNextDeployment(projectId, encodedEnvId, {
            verbose: true
          })
        : await client.getCurrentDeployment(projectId, encodedEnvId, {
            verbose: true
          });

      dispatch({
        type: LOAD_DEPLOYMENT_SUCCESS,
        next,
        payload: deployment,
        meta: {
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId,
          deploymentId: next ? NEXT_DEPLOYMENT_ID : CURRENT_DEPLOYMENT_ID,
          schemas: (
            deployment._links?.self?.meta as {
              get: {
                responses: {
                  default: {
                    content: {
                      "application/json": { schema: { properties: unknown } };
                    };
                  };
                };
              };
            }
          ).get.responses.default.content["application/json"].schema.properties
        }
      });

      dispatch(
        updateServices({
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId,
          deploymentId: next ? NEXT_DEPLOYMENT_ID : CURRENT_DEPLOYMENT_ID,
          deployment
        })
      );
    } catch (error) {
      if (![404, 403].includes((error as { code: number }).code)) {
        const errorMessage = isJson(error)
          ? error
          : "An error occurred while attempting to load deployment.";

        logger(errorMessage, {
          action: "load_deployment",
          meta: {
            organizationDescriptionId,
            environmentDescriptionId,
            projectDescriptionId,
            deploymentId: next ? NEXT_DEPLOYMENT_ID : CURRENT_DEPLOYMENT_ID
          }
        });
      }
      dispatch({
        type: LOAD_DEPLOYMENT_FAILURE,
        next,
        error: true,
        payload: {
          error,
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          deploymentId: next ? NEXT_DEPLOYMENT_ID : CURRENT_DEPLOYMENT_ID
        }
      });
    }
  };
};

export const updateDeployment = (
  deployment: Deployment,
  data: DeploymentUpdateParams,
  organizationDescriptionId: string,
  projectDescriptionId: string,
  environmentDescriptionId: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: UPDATE_DEPLOYMENT_START,
      payload: {
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      }
    });
    try {
      const result = await deployment.update(data);

      const updateDeployment: Deployment = result.getEntity();
      dispatch({
        type: UPDATE_DEPLOYMENT_SUCCESS,
        payload: updateDeployment,
        meta: {
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId,
          deploymentId: NEXT_DEPLOYMENT_ID
        }
      });

      dispatch(
        updateServices({
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId,
          deploymentId: NEXT_DEPLOYMENT_ID,
          deployment: updateDeployment
        })
      );
      return UPDATE_DEPLOYMENT_SUCCESS;
    } catch (error) {
      dispatch({
        type: UPDATE_DEPLOYMENT_FAILURE,
        payload: {
          error,
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId
        }
      });
    }
  };
};

export const selectDeployment = (
  state: RootState,
  {
    organizationId,
    projectId,
    environmentId,
    deploymentId
  }: {
    organizationId?: string;
    projectId?: string;
    environmentId?: string;
    deploymentId: string;
  }
) => {
  const environment =
    environmentId ||
    (typeof projectId === "undefined"
      ? undefined
      : gitProjectSelector(state, { organizationId, projectId })
          ?.default_branch);

  if (
    typeof state.deployment.data !== "undefined" &&
    organizationId &&
    projectId
  ) {
    return state.deployment?.data[organizationId]?.[projectId]?.[
      environment || "master"
    ]?.[deploymentId];
  }
};

export const currentDeployment = (
  state: RootState,
  {
    organizationId,
    projectId,
    environmentId
  }: { organizationId?: string; projectId?: string; environmentId?: string }
) => {
  return selectDeployment(state, {
    organizationId,
    projectId,
    environmentId,
    deploymentId: CURRENT_DEPLOYMENT_ID
  });
};

export const selectDeploymentSettings = (
  state: RootState,
  {
    projectId,
    environmentId,
    organizationId
  }: {
    projectId: string;
    environmentId: string;
    organizationId: string;
  }
) => {
  if (typeof state.deployment.settings !== "undefined") {
    return state.deployment.settings?.[organizationId]?.[projectId]?.[
      environmentId
    ];
  }
};

type AppAndServiceProps = {
  organizationId: string;
  projectId: string;
  environmentId: string;
  appName?: string;
  key: "webapps" | "workers" | "routes" | "services";
};

export const currentAppAndServiceSelector = createSelector(
  currentDeployment,
  (_: RootState, params: AppAndServiceProps) => params,
  (currentDeploymentData, { appName, key }) =>
    appName ? currentDeploymentData?.[key]?.[appName] : undefined
);

export const currentAppSelector = createSelector(
  currentAppAndServiceSelector,
  (_: RootState, params: AppAndServiceProps) => params,
  (app, { appName, key }) => {
    if (key === "webapps" && appName) {
      const { iconName } = getServiceIcon(app as DeploymentService);

      const a = getDeploymentWebApps({
        name: appName,
        iconName,
        currentLine: 0,
        column: 0,
        app: app as DeploymentService
      });

      return a;
    }
  }
);

export const currentServiceSelector = createSelector(
  currentAppAndServiceSelector,
  (_: RootState, params: AppAndServiceProps) => params,
  (service, { appName: serviceName, key }) => {
    if (key === "services" && serviceName) {
      const type = service?.type?.split(":")?.[1];

      const s = getDeploymentServices({
        name: serviceName,
        type,
        currentLine: 0,
        column: 0,
        service: service as DeploymentService
      });

      return s;
    }
  }
);

export const nextDeployment = (
  state: RootState,
  {
    organizationId,
    projectId,
    environmentId
  }: { organizationId: string; projectId?: string; environmentId?: string }
) => {
  return selectDeployment(state, {
    organizationId,
    projectId,
    environmentId,
    deploymentId: NEXT_DEPLOYMENT_ID
  });
};

export const deploymentSelectors = {
  currentDeployment,
  nextDeployment
};

export const currentDeploymentClassSelector = (
  state: RootState,
  {
    organizationId,
    projectId,
    environmentId
  }: { organizationId: string; projectId?: string; environmentId?: string }
) => {
  return currentDeployment(state, {
    organizationId,
    projectId,
    environmentId
  });
};

export interface TotalResources {
  cpu: {
    shared: number;
    guaranteed: number;
    total: number;
  };
  ram: number;
  instances: number;
  disk: number;
  status?: string;
}

export const resourcesAccumulatorHandler = (
  totalResources: TotalResources,
  resourceItem: { [K in keyof TotalResources]?: TotalResources[K] | null }
): TotalResources & { status: string } => {
  const updatedCpu = {
    shared: resourceItem?.cpu?.shared ?? 0,
    guaranteed: resourceItem?.cpu?.guaranteed ?? 0,
    total: resourceItem?.cpu?.total ?? 0
  };
  const ram = resourceItem?.ram ?? 0;
  const instances = resourceItem?.instances ?? 1;
  const disk = resourceItem?.disk ?? 0;
  const status = resourceItem?.status ?? "";

  updatedCpu.shared = totalResources.cpu.shared + updatedCpu.shared * instances;
  updatedCpu.guaranteed =
    totalResources.cpu.guaranteed + updatedCpu.guaranteed * instances;
  updatedCpu.total = updatedCpu.shared + updatedCpu.guaranteed;

  return {
    cpu: updatedCpu,
    ram: totalResources.ram + ram * instances,
    instances: totalResources.instances + instances,
    disk: totalResources.disk + disk,
    status
  };
};

export const resourcesFormatHandler = (
  totalResources: TotalResources
): TotalResources => ({
  cpu: {
    shared: roundedNumber(totalResources.cpu.shared, 3),
    guaranteed: roundedNumber(totalResources.cpu.guaranteed, 3),
    total: roundedNumber(totalResources.cpu.total, 3)
  },
  ram: roundedNumber(totalResources.ram / 1024, 3),
  instances: totalResources.instances,
  disk: roundedNumber(totalResources.disk / 1024, 3),
  status: totalResources.status
});

export type OneDeploymentResourceType = TotalResources & {
  profileLabel?: string;
  crons: number;
};

export const oneDeploymentResourceSelector = (
  state: RootState,
  {
    organizationId,
    projectId,
    environmentId,
    resourceType,
    appName
  }: {
    organizationId: string;
    projectId?: string;
    environmentId?: string;
    resourceType: ResourceCollectionType;
    appName: string;
  }
): OneDeploymentResourceType => {
  const currentDeploymentValue = currentDeployment(state, {
    organizationId,
    projectId,
    environmentId
  });
  const currentResource = {
    cpu: {
      shared: 0,
      guaranteed: 0,
      total: 0
    },
    ram: 0,
    instances: 0,
    disk: 0,
    status: ""
  };
  const allProfiles = currentDeploymentValue?.container_profiles;
  const resource = currentDeploymentValue?.[resourceType];
  const resourceApp = resource?.[appName];
  const cronsQuantity = Object.keys(resourceApp?.crons ?? {}).length;
  const profileSize = resourceApp?.resources?.profile_size;
  const containerProfile = resourceApp?.container_profile;
  const currentProfile = allProfiles?.[containerProfile!];
  const currentCpu = currentProfile?.[profileSize!]?.cpu ?? 0;
  const cpu = {
    shared: profileSize?.endsWith(".gc") ? 0 : currentCpu,
    guaranteed: profileSize?.endsWith(".gc") ? currentCpu : 0,
    total: currentCpu
  };
  const ram = currentProfile?.[profileSize!].memory ?? 0;
  const disk = resourceApp?.disk ?? 0;
  const instances = resourceApp?.instance_count ?? 1;
  const status = currentDeploymentValue?.environment_info?.status ?? "";

  const currentResourceFormatted = resourcesFormatHandler(
    resourcesAccumulatorHandler(currentResource, {
      cpu,
      ram,
      instances,
      disk,
      status
    })
  );

  return {
    ...currentResourceFormatted,
    profileLabel: DIALOG_RESOURCE_PROFILE[containerProfile!],
    crons: cronsQuantity
  };
};

export const deploymentResourcesHandler = (deployment?: Deployment) => {
  let totalResources = {
    cpu: {
      shared: 0,
      guaranteed: 0,
      total: 0
    },
    ram: 0,
    instances: 0,
    disk: 0,
    status: ""
  };
  const { webapps, workers, services } = deployment ?? {
    webapps: undefined,
    workers: undefined,
    services: undefined
  };
  const status = deployment?.environment_info?.status ?? "";

  try {
    Object.values({ webapps, workers, services }).forEach(resourceCollection =>
      Object.values(resourceCollection ?? {}).forEach(data => {
        const containerProfiles =
          deployment?.container_profiles[data.container_profile ?? ""];

        const profileSize = data?.resources.profile_size ?? "";

        const currentCpu = containerProfiles?.[profileSize].cpu ?? 0;

        const cpu = {
          shared: profileSize?.endsWith(".gc") ? 0 : currentCpu,
          guaranteed: profileSize?.endsWith(".gc") ? currentCpu : 0,
          total: currentCpu
        };

        const ram = containerProfiles?.[profileSize].memory;

        const { disk, instance_count: instances } = data;

        totalResources = resourcesAccumulatorHandler(totalResources, {
          cpu,
          ram,
          instances,
          disk,
          status
        });
      })
    );

    return resourcesFormatHandler(totalResources);
  } catch {
    return {
      cpu: {
        shared: 0,
        guaranteed: 0,
        total: 0
      },
      ram: 0,
      instances: 0,
      disk: 0,
      status
    };
  }
};

export const deploymentResourcesSelector = (
  state: RootState,
  {
    organizationId,
    projectId,
    environmentId
  }: { organizationId: string; projectId?: string; environmentId?: string }
) => {
  const current = currentDeployment(state, {
    organizationId,
    projectId,
    environmentId
  });

  return deploymentResourcesHandler(current);
};

export const deploymentSizingApiEnabledSelector = (
  state: RootState,
  {
    organizationId,
    projectId,
    environmentId
  }: { organizationId: string; projectId?: string; environmentId?: string }
) => {
  const deployment =
    currentDeploymentClassSelector(state, {
      organizationId,
      projectId,
      environmentId
    }) ??
    nextDeployment(state, {
      organizationId,
      projectId,
      environmentId
    });

  const deploymentSizingApi = (
    deployment?.project_info as
      | { settings: { sizing_api_enabled: boolean } | undefined }
      | undefined
  )?.settings?.sizing_api_enabled;

  const projectSettingSizingApi = projectSettingsSizingApiSelector(state, {
    projectId: projectId!
  });

  if (typeof deploymentSizingApi === "undefined") {
    return projectSettingSizingApi;
  }
  return deploymentSizingApi;
};

export const deploymentStatusCodesSelector = createSelector(
  deploymentSelector,
  (
    _: RootState,
    params: { organizationId: string; projectId: string; environmentId: string }
  ) => params,
  (deployment, { organizationId, projectId, environmentId }) => {
    const { current, next } = deployment?.responseCode?.[organizationId]?.[
      projectId
    ]?.[environmentId] ?? {
      current: undefined,
      next: undefined
    };

    return { current, next };
  }
);

const deploymentValueChecks = (
  deploymentNext: Deployment | undefined,
  deploymentCurrent: Deployment | undefined
) => {
  const nextValues: Record<string, any> = {};
  const currentValues: Record<string, any> = {};

  const nextDeployment = {
    webapps: deploymentNext?.webapps,
    workers: deploymentNext?.workers,
    services: deploymentNext?.services
  };

  const currentDeployment = {
    webapps: deploymentCurrent?.webapps,
    workers: deploymentCurrent?.workers,
    services: deploymentCurrent?.services
  };

  [nextDeployment, currentDeployment].forEach((deployment, i) => {
    const deploymentGroup = i === 0 ? nextValues : currentValues;
    objectEntries(deployment).forEach(
      ([resourceCollectionType, resourceCollection]) => {
        objectEntries(resourceCollection ?? {}).forEach(([label, data]) => {
          setDeep(
            deploymentGroup,
            [resourceCollectionType, label, "instance_count"],
            data?.instance_count
          );

          setDeep(
            deploymentGroup,
            [resourceCollectionType, label, "resources", "profile_size"],
            data?.resources.profile_size
          );

          setDeep(
            deploymentGroup,
            [resourceCollectionType, label, "disk"],
            data?.disk
          );
        });
      }
    );
  });

  return { nextValues, currentValues };
};

export const resourcesDeploymentStatusSelector = (
  state: RootState,
  {
    organizationId,
    projectId = "",
    environmentId = ""
  }: { organizationId: string; projectId?: string; environmentId?: string }
): "Bad configuration" | "No resources configured" | "Build failed" | "" => {
  const { current: currentCode, next: nextCode } =
    deploymentStatusCodesSelector(state, {
      organizationId,
      projectId,
      environmentId
    });

  const deploymentCurrent = currentDeploymentClassSelector(state, {
    organizationId,
    projectId,
    environmentId
  });
  const deploymentNext = nextDeployment(state, {
    organizationId,
    projectId,
    environmentId
  });

  const { nextValues, currentValues } = deploymentValueChecks(
    deploymentNext,
    deploymentCurrent
  );

  if (nextCode === 400 && currentCode === 404) {
    return "Bad configuration";
  } else if (
    (nextCode === 200 &&
      currentCode === 200 &&
      isDifferent(nextValues, currentValues)) ||
    (nextCode === 200 && currentCode === 404)
  ) {
    return "No resources configured";
  } else if (nextCode?.toString().match(/^[45]/) && currentCode === 200) {
    return "Build failed";
  }
  return "";
};

export const allResourcesHaveProfileSizeSelector = createSelector(
  nextDeployment,
  nextDeployment => {
    if (typeof nextDeployment === "undefined") {
      return;
    }
    let hasProfileSize = true;
    const resources = {
      webapps: nextDeployment?.webapps,
      workers: nextDeployment?.workers,
      services: nextDeployment?.services
    };

    Object.values(resources).forEach(resourceCollection => {
      Object.values(resourceCollection).forEach(data => {
        hasProfileSize = hasProfileSize && !!data?.resources?.profile_size;
      });
    });

    return hasProfileSize;
  }
);

type DeploymentState = {
  data?: Record<
    string,
    | Record<
        string,
        | Record<string, Record<string, Deployment | undefined> | undefined>
        | undefined
      >
    | undefined
  >;
  loadingNext?: boolean;
  loadingCurrent?: boolean;
  loading?: boolean;
  updateLoading?: boolean;
  schemas?: unknown;
  errors?: Record<
    string,
    | Record<
        string,
        | Record<
            string,
            Record<string, { message: string } | undefined> | undefined
          >
        | undefined
      >
    | undefined
  >;
  settings?: {
    [organizationId: string]: {
      [projectId: string]: {
        [environmentId: string]: {
          explicit_deployments_enabled: boolean;
          id: string;
        };
      };
    };
  };

  responseCode?: Record<
    string,
    | Record<
        string,
        Record<string, Record<string, number> | undefined> | undefined
      >
    | undefined
  >;
  details?: unknown;
};

export type DeploymentSettings = {
  id: string;
  explicit_deployments_enabled: boolean;
};
type DeploymentAction =
  | { type: typeof LOAD_DEPLOYMENT_SETTINGS_START; next: boolean }
  | {
      type: typeof LOAD_DEPLOYMENT_SETTINGS_SUCCESS;
      payload: DeploymentSettings;
      meta: {
        projectId: string;
        environmentId: string;
        organizationId: string;
      };
    }
  | {
      type: typeof LOAD_DEPLOYMENT_SETTINGS_FAILURE;

      payload: {
        error: { message: string; code: number };
        organizationId: string;
        projectId: string;
        environmentId: string;
      };
      meta: {
        projectId: string;
        environmentId: string;
      };
    }
  | { type: typeof LOAD_DEPLOYMENT_START; next: boolean }
  | {
      type: typeof LOAD_DEPLOYMENT_SUCCESS;
      payload: Deployment;
      next: boolean;
      meta: {
        organizationDescriptionId: string;
        projectDescriptionId: string;
        environmentDescriptionId: string;
        deploymentId: string;
        schemas: unknown;
      };
    }
  | {
      type: typeof LOAD_DEPLOYMENT_FAILURE;
      next: boolean;
      payload: {
        error: { message: string; code: number };
        organizationDescriptionId: string;
        projectDescriptionId: string;
        environmentDescriptionId: string;
        deploymentId: "current" | "next";
      };
    }
  | {
      type: typeof UPDATE_DEPLOYMENT_START;
      payload: {
        organizationDescriptionId: string;
        projectDescriptionId: string;
        environmentDescriptionId: string;
      };
    }
  | {
      type: typeof UPDATE_DEPLOYMENT_SUCCESS;
      payload: Deployment;
      meta: {
        organizationDescriptionId: string;
        projectDescriptionId: string;
        environmentDescriptionId: string;
        deploymentId: string;
      };
    }
  | {
      type: typeof UPDATE_DEPLOYMENT_FAILURE;
      payload: {
        error: { message: string; code: number };
        organizationDescriptionId: string;
        environmentDescriptionId: string;
        projectDescriptionId: string;
      };
    };

export default function environmentDeploymentReducer(
  state: DeploymentState = {},
  action: DeploymentAction
): DeploymentState {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case LOAD_DEPLOYMENT_SETTINGS_START:
      return { ...newState, loading: true };
    case LOAD_DEPLOYMENT_SETTINGS_SUCCESS:
      setDeep(
        newState,
        [
          "settings",
          action.meta.organizationId,
          action.meta.projectId,
          action.meta.environmentId
        ],
        action.payload
      );

      return {
        ...newState,
        loading: false
      };
    case LOAD_DEPLOYMENT_SETTINGS_FAILURE: {
      const { error, organizationId, projectId, environmentId } =
        action.payload;
      setDeep(
        newState,
        ["errors", organizationId, projectId, environmentId, "settings"],
        error
      );
      setDeep(
        newState,
        ["responseCode", organizationId, projectId, environmentId, "settings"],
        error.code
      );
      return {
        ...newState,
        loading: false
      };
    }

    case LOAD_DEPLOYMENT_START:
      if (action.next) {
        return { ...newState, loading: true, loadingNext: true };
      }
      return { ...newState, loading: true, loadingCurrent: true };
    case LOAD_DEPLOYMENT_SUCCESS: {
      setDeep(
        newState,
        [
          "data",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.meta.environmentDescriptionId,
          action.meta.deploymentId
        ],
        action.payload
      );

      setDeep(
        newState,
        [
          "errors",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.meta.environmentDescriptionId,
          action.meta.deploymentId
        ],
        undefined
      );

      setDeep(
        newState,
        [
          "responseCode",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.meta.environmentDescriptionId,
          action.meta.deploymentId
        ],
        200
      );
      if (action.next) {
        return {
          ...newState,
          schemas: action.meta.schemas,
          loading: false,
          loadingNext: false
        };
      }
      return {
        ...newState,
        schemas: action.meta.schemas,
        loading: false,
        loadingCurrent: false
      };
    }
    case LOAD_DEPLOYMENT_FAILURE: {
      const {
        error,
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId,
        deploymentId
      } = action.payload;

      setDeep(
        newState,
        [
          "errors",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          deploymentId
        ],
        error
      );

      setDeep(
        newState,
        [
          "responseCode",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          deploymentId
        ],
        error.code
      );
      if (action.next) {
        return {
          ...newState,
          loading: false,
          loadingNext: false
        };
      }
      return {
        ...newState,
        loading: false,
        loadingCurrent: false
      };
    }
    case UPDATE_DEPLOYMENT_START: {
      const {
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      } = action.payload;

      setDeep(
        newState,
        [
          "errors",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          CURRENT_DEPLOYMENT_ID
        ],
        undefined
      );

      setDeep(
        newState,
        [
          "errors",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          NEXT_DEPLOYMENT_ID
        ],
        undefined
      );
      return {
        ...newState,
        updateLoading: true
      };
    }
    case UPDATE_DEPLOYMENT_SUCCESS:
      setDeep(
        newState,
        [
          "data",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.meta.environmentDescriptionId,
          action.meta.deploymentId
        ],
        action.payload
      );

      setDeep(
        newState,
        [
          "responseCode",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.meta.environmentDescriptionId,
          action.meta.deploymentId
        ],
        200
      );

      return {
        ...newState,
        updateLoading: false
      };
    case UPDATE_DEPLOYMENT_FAILURE: {
      const {
        error,
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      } = action.payload;

      setDeep(
        newState,
        [
          "errors",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          NEXT_DEPLOYMENT_ID
        ],
        error
      );

      setDeep(
        newState,
        [
          "responseCode",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          NEXT_DEPLOYMENT_ID
        ],
        error.code
      );

      return {
        ...newState,
        updateLoading: false
      };
    }
    default:
      return newState;
  }
}

export const deploymentHasLanguageSelector = (
  state: RootState,
  {
    environmentId,
    organizationId,
    projectId
  }: { environmentId?: string; organizationId?: string; projectId?: string },
  languages: string[] = []
) => {
  const deployment = currentDeployment(state, {
    environmentId,
    organizationId,
    projectId
  });
  return Object.keys(deployment?.webapps || {}).some(appName =>
    languages.some(supportType => {
      const appType = deployment?.webapps[appName].type.toLowerCase();
      return appType?.indexOf(supportType.toLowerCase()) !== -1;
    })
  );
};

export const loadingDeploymentSelector = (state: RootState) =>
  state.deployment.loading;

export const loadingNextDeploymentSelector = (state: RootState) =>
  state.deployment.loadingNext;

export const loadingCurrentDeploymentSelector = (state: RootState) =>
  state.deployment.loadingCurrent;
