import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";

import { Notification, NotificationId } from "./Notifications.types";

export const name = "notifications";

const initialState = [] as Array<Notification>;

const slice = createSlice({
  name,
  initialState,
  reducers: {
    add: (state, action: PayloadAction<Notification>) => [
      ...state,
      action.payload
    ],
    flagForRemoval: (state, action: PayloadAction<NotificationId>) =>
      state.map(notification => ({
        ...notification,
        flaggedForRemoval:
          notification.id === action.payload
            ? true
            : notification.flaggedForRemoval
      })),
    remove: (state, action: PayloadAction<NotificationId>) =>
      state.filter(notification => notification.id !== action.payload)
  }
});

export default slice.reducer;

export const { add, remove, flagForRemoval } = slice.actions;

export const selectNotifications = (state: RootState) => state[name];

export const notificationByTitleSelector = createSelector(
  selectNotifications,
  (_: RootState, params: { title: string }) => params,
  (notifications, { title }) =>
    notifications.find(notification => notification.title === title)
);
